<template>
    <div>
        <!-- 头部 -->
        <section class="section  panel header_title_box">
            <div class=" col-lg-12 container_max">
                <div class="col-lg-12  flex-wb wow animate__fadeInUp">
                    <div class="T48px_Regular col-lg-5 colorfff left_text_box" :class="$t('case.title1') == 'Riding the Waves,' ? 'left_text_box_max' : '' ">
                        <span class="color1D73FF">{{ $t("case.title1") }}</span>
                        <span>{{ $t("case.title2") }}</span>
                    </div>
                    <div class="T16px_Regular col-lg-6 colorfff rigth_text_box">
                       {{ $t("case.rigth_text_box") }}
                    </div>
                </div>

                <div class="header_menu_box col-lg-12">
                    <button class="menu_btn T18px_Regular colorfff act animation_right">{{ $t("case.menu_btn1") }}</button>
                    <button class="menu_btn T18px_Regular colorfff animation_right">{{ $t("case.menu_btn2") }}</button>
                </div>

                <div class="project_list col-lg-12">
                    <div class="project_item" v-for="(item, index) in projecList" @click="routeUrlBtn(item.routeUrl)"
                        :key="index">

                        <div class="project_logo_box">
                            <img :src="item.url" class="project_logo" alt="">
                        </div>
                        <div class="info_box flex-wb">
                            <div class="left_info_box flex-w">
                                <span class="T36px_Regular color1D73FF"> {{ item.name }}</span>
                                <span class="geli"></span>
                                <span class="T20px_Regular colorfff"> {{ item.tips }}</span>
                            </div>
                            <div class="rigth_info_box">
                                <span class="child_item_btn T18px_Regular colorfff"
                                    v-for="(items, indexs) in item.child" :key="indexs">
                                    {{ items }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>


    </div>
</template>

<script>
import {
    WOW
} from 'wowjs'
export default {
    data() {
        return {
            projecList: [
                {
                    url: 'https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/mx.png',
                    name: this.$t("case.projecList1_name"),
                    tips:  this.$t("case.projecList1_tips"),
                    child: [
                        this.$t("case.projecList1_child1"), this.$t("case.projecList1_child1")
                    ],
                    routeUrl: '/moXun'
                },
                {
                    url: 'https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/shj.png',
                    name: this.$t("case.projecList2_name"),
                    tips:  this.$t("case.projecList2_tips"),
                    child: [
                        this.$t("case.projecList2_child1"), this.$t("case.projecList2_child1")
                    ],
                    routeUrl: '/sanhaiJing'
                },
                {
                    url:'https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/xygl.png',
                     name: this.$t("case.projecList3_name"),
                    tips:  this.$t("case.projecList3_tips"),
                    child: [
                        this.$t("case.projecList3_child1"), this.$t("case.projecList3_child1")
                    ],
                    routeUrl: '/college'
                }, {
                    url: 'https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/rz.png',
                     name: this.$t("case.projecList4_name"),
                    tips:  this.$t("case.projecList4_tips"),
                    child: [
                        this.$t("case.projecList4_child1"), this.$t("case.projecList4_child1")
                    ],
                    routeUrl: '/ninja'
                }
            ]
        };
    },
    mounted() {
        const wow = new WOW();
        wow.init();
    },
    methods: {
        /**
         * 跳转项目路由
         * @param {*} url 跳转相对路由
        */
        routeUrlBtn(url) {
            this.$router.push(url)
        }
    },
};
</script>

<style lang="less" scoped>
.header_title_box {
    min-height: 100vh;
    padding-top: 5.6875rem;
    background: #15171D;

    .container_max {
        // width: 100vw;
        padding: 0 5.8438rem;

        position: relative;

        .left_text_box {
            width: 14.0313rem;
            height: 3.875rem;
        }
        .left_text_box_max{
            width: 24.0313rem;
        }

        .rigth_text_box {
            width: 21.375rem;
            line-height: 1.3125rem;
        }

        .header_menu_box {
            margin-top: 3.5rem;
            padding-bottom: 1.0313rem;
            border-bottom: .0313rem solid #FFFFFF;

            .menu_btn {
                border: .0313rem solid #FFFFFF;
                border-radius: 1.75rem;
                padding: .2188rem 1rem;
                background: transparent;
                margin-right: 1.4063rem;
                position: relative;
                z-index: 1;
                overflow: hidden;

                &:after {
                    border-radius: 1.75rem;
                }

            }

            .act {
                background: #1D73FF;
                border: .0313rem solid #1D73FF;
            }
        }

        .project_list {

            .project_item {
                padding-top: 3.875rem;
                width: 34.7188rem;
                overflow: hidden;

                &:hover {
                    .project_logo {
                        transform: scale(1.05);

                    }
                }

                .project_logo_box {
                    width: 100%;
                    height: 19.3438rem;
                    overflow: hidden;

                    .project_logo {
                        width: 100%;
                        height: 100%;
                        transition: all .8s;
                    }
                }


                .info_box {
                    margin-top: 1.0313rem;

                    .geli {
                        display: inline-block;
                        width: .25rem;
                        height: 0;
                        border: .0625rem solid #FFFFFF;
                        margin: 0 .5625rem;
                    }
                }

                .child_item_btn {
                    padding: .2188rem 1rem;
                    border: .0313rem solid #FFFFFF;
                    border-radius: 1.75rem;
                    margin-left: 1.1875rem;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
</style>